import React from 'react'
import {NavLink} from 'react-router-dom'
import Logo from '../../assets/images/logo-website.png'
import {ReactComponent as TwitterLogo} from '../../assets/icons/twitter.svg'

export const Navbar = () => (
  <div className="container-xl px-4">
    <header
      className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
      <NavLink to="/"
               className="col-12 col-sm-12 col-md-6 col-lg-4">
        <img src={Logo} alt="Lit Rooster" style={{width: '100%'}}/>
      </NavLink>

      {/*<ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
        <li><NavLink to="/" className="nav-link px-2 link-secondary">How we are</NavLink></li>
        <li><NavLink to="/" className="nav-link px-2 link-dark">FAQ</NavLink></li>
        <li><NavLink to="/" className="nav-link px-2 link-dark">Our Mission</NavLink></li>
        <li><NavLink to="/" className="nav-link px-2 link-dark">FAQ</NavLink></li>
        <li><NavLink to="/" className="nav-link px-2 link-dark">Team</NavLink></li>
      </ul>*/}

      <div className="col-12 col-sm-12 col-md-6 col-lg-4 text-center text-md-end">
        <a href="https://twitter.com/LitRooster" target="_blank" rel="noreferrer" className="comic-button comic-background-blue">
          <span className="comic-button-text">
            <TwitterLogo/>
          </span>
        </a>
        <button className="comic-button">
          <span className="comic-button-text">Mint Soon...</span>
        </button>
      </div>
    </header>
  </div>
)