import React from 'react'
import StoryImage1 from '../../../assets/images/story/story1.jpg'
import StoryImage2 from '../../../assets/images/story/story2.jpg'
import StoryImage3 from '../../../assets/images/story/story3.jpg'
import StoryImage4 from '../../../assets/images/story/story4.jpg'
import StoryImage5 from '../../../assets/images/story/story5.jpg'
import StoryImage6 from '../../../assets/images/story/story6.jpg'
import StoryImage7 from '../../../assets/images/story/story7.jpg'
import StoryImage8 from '../../../assets/images/story/story8.jpg'

const Story = () => (
  <>
  <div className="row">
    <div className="col-12 col-sm-12 col-md-8 col-lg-8 p-0">
      <div className="panel">
        <img src={StoryImage1} alt="Comic Story 1" style={{width: '100%'}}/>
        <p className="text top-left fs-3">Somewhere in chickenverse</p>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 p-0">
      <div className="panel">
        <img src={StoryImage2} alt="Comic Story 2" style={{width: '100%'}}/>
        <p className="text bottom-left fs-3">Government</p>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-0">
      <div className="panel">
        <img src={StoryImage3} alt="Comic Story 3" style={{width: '100%'}}/>
      {/*<p className="speech">A speech bubble</p>*/}
      </div>
    </div>
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-0">
      <div className="panel">
        <img src={StoryImage4} alt="Comic Story 4" style={{width: '100%'}}/>
        <p className="text bottom-right fs-4">-"Mr. Presideeent..."</p>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-0">
      <div className="panel">
        <img src={StoryImage5} alt="Comic Story 5" style={{width: '100%'}}/>
        <p className="text bottom-right fs-4">-"What happens?"</p>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-0">
      <div className="panel">
        <img src={StoryImage6} alt="Comic Story 6" style={{width: '100%'}}/>
        <p className="text bottom-right fs-5">-"All data of our citizens was stolen by someone from another universe"</p>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-0">
      <div className="panel">
        <img src={StoryImage7} alt="Comic Story 7" style={{width: '100%'}}/>
        <p className="text top-left fs-5">-"I think they want to sell it..."</p>
        <p className="text bottom-right fs-4">Opening laptop...</p>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 p-0">
      <div className="panel">
        <img src={StoryImage8} alt="Comic Story 8" style={{width: '100%'}}/>
        <p className="text bottom-left fs-5">-"Oh sh*t, they give it for FREE..."</p>
      </div>
    </div>
    <div className="p-0 col-12">
      <div className="panel section-header comic-background-yellow">
        <h1>To be continued...</h1>
      </div>
    </div>
  </div>
  </>
)

export default Story