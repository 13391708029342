import React, {useEffect, useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import Layout from './components/Layout/Layout'
import {Home} from './pages/Home/Home'
import FullPageLoader from './components/FullPageLoader/FullPageLoader'


const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <FullPageLoader toggle={loading}/>
      <Layout>
        <Routes>
          <Route path="/" element={<Home/>}/>
        </Routes>
      </Layout>
    </>
  )
}

export default App
