import React from 'react'
import './FullPageLoader.scss'
import { useTransition, animated } from '@react-spring/web'
import Logo from '../../assets/images/logo-square.png'

const FullPageLoader = ({toggle}) => {
  const transitions = useTransition(toggle, {
    from: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return transitions(
    (styles, item) => (
      item &&
      <animated.div className="full-page-loader" style={styles}>
        <img src={Logo} alt="logo" className="loader-spinner bounce"/>
      </animated.div>
  ))
}

export default FullPageLoader