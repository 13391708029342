import React from 'react'
import {NavLink} from 'react-router-dom'
import Logo from '../../assets/images/logo-website.png'
import {ReactComponent as TwitterLogo} from '../../assets/icons/twitter.svg'

export const Footer = () => (
  <div className="container-xl px-4">
    <footer className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mt-4 border-top">
      <div className="col-9 col-sm-6 col-md-6 col-lg-6">
        <NavLink to="/" className="col-12">
          <img src={Logo} alt="Lit Rooster" style={{maxWidth: 200}}/>
        </NavLink>
        <small className="col-12 copyright-label">© 2022 Lit Lab. All Rights Reserved.</small>
      </div>

      <div className="col-3 col-sm-6 col-md-6 col-lg-6 text-center text-sm-end">
        <a href="https://twitter.com/LitRooster" target="_blank" rel="noreferrer" className="comic-button comic-background-blue">
          <span className="comic-button-text">
            <TwitterLogo width={24} height={24}/>
          </span>
        </a>
      </div>
    </footer>
  </div>
)